import cx from 'classnames';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import icon from '../../assets/icons/icon-breadcrumb-arrow.svg';
import styles from './Breadcrumbs.module.scss';

type Crumb = {
  title: string;
  link?: string;
};

const Breadcrumbs = ({
  classNames,
  crumbs,
}: {
  classNames?: string;
  crumbs: Array<Crumb>;
}) => {
  return (
    <div className={cx(classNames, styles.breadcrumbs)}>
      {crumbs.map((crumb, i) => {
        return (
          <React.Fragment key={`crumb-${i}`}>
            {i > 0 && (
              <img
                className={styles.arrow}
                src={icon}
                width={20}
                height={20}
                alt=""
                loading="lazy"
              />
            )}
            {crumb.link ? (
              <Link to={crumb.link} className={styles.crumb}>
                {crumb.title}
              </Link>
            ) : (
              <span className={styles.crumb}>{crumb.title}</span>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
