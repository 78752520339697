import cx from 'classnames';
import { graphql } from 'gatsby';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import findLast from 'lodash.findlast';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import FAQs from '../components/FAQ/List';
import Layout from '../components/Layout/Layout';
import { titlePathnameMap } from '../constants';
import { FAQType, FormattedFAQCategoryType, StripType } from '../types';
import { getFormattedFAQ } from '../utils';
import styles from './FAQItem.module.scss';

type Breadcrumb = {
  title: string;
  link: string;
};

const getSubCat = (cats: Array<FormattedFAQCategoryType>) => {
  return cats.find((cat) => {
    if (cat.slug === 'consumer') {
      return false;
    }
    if (cat.slug === 'merchant') {
      return false;
    }

    return true;
  });
};

export default function FAQItem({
  pageContext: { majorCatSlug },
  data: {
    contentful: {
      faq: rawFAQ,
      stripCollection: { items: strips },
    },
  },
}: {
  pageContext: { majorCatSlug: string };
  data: {
    contentful: {
      faq: FAQType;
      stripCollection: {
        items: Array<StripType>;
      };
    };
  };
}) {
  const { t, originalPath, i18n } = useI18next();
  const faq = getFormattedFAQ(rawFAQ);

  const breadcrumbs: Breadcrumb[] = [
    {
      title: t('faq'),
      link: '/support/',
    },
    {
      title: t(`faq-major-cat-${majorCatSlug}`),
      link: `/support/${majorCatSlug}/`,
    },
  ];

  const subCat = getSubCat(faq.cats);

  if (subCat) {
    breadcrumbs.push({
      title: subCat.title,
      link: `/support/${majorCatSlug}/${subCat.slug}/#faq`,
    });
  }

  const parentLink =
    (findLast(breadcrumbs, (crumb) => crumb.link) as Breadcrumb)?.link ||
    '/support/';

  const titlePathname = (i18n.language !== 'ja' ? ['', i18n.language] : [''])
    .concat(['support', majorCatSlug, ''])
    .join('/');

  return (
    <Layout
      title={`${faq.title} | ${titlePathnameMap[titlePathname]}`}
      description={
        faq.answerText.length > 103
          ? `${faq.answerText.substring(0, 100)}…`
          : faq.answerText
      }
      className={cx(styles.mainWrapper, styles[majorCatSlug])}
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      strips={strips}
    >
      <div className={styles.main}>
        <Breadcrumbs classNames={styles.breadcrumbs} crumbs={breadcrumbs} />
        <div className={styles.itemWrapper}>
          <h1>{faq.title}</h1>
          <div className={styles.answer}>{faq.answer}</div>
          <div className={styles.actions}>
            <Link to={parentLink} className={styles.action}>
              {t('back')}
            </Link>
          </div>
        </div>
      </div>
      {faq.relatedArticles.length > 0 && (
        <div className={styles.related}>
          <div className={styles.content}>
            <h2>{t('related-articles')}</h2>
            <FAQs
              className={styles.relateArticles}
              faqs={faq.relatedArticles}
              majorCatSlug={majorCatSlug}
            />
          </div>
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query FAQEntryPageQuery($id: String!, $language: String!, $dev: Boolean!) {
    contentful {
      faq(id: $id, locale: $language, preview: $dev) {
        sys {
          id
        }
        title
        titleEn: title(locale: "en")
        answer {
          json
          links {
            assets {
              block {
                url
                height
                width
                sys {
                  id
                }
              }
            }
            entries {
              block {
                ... on Contentful_Table {
                  caption
                  contentMarkdown
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
        relatedArticlesCollection(locale: $language) {
          items {
            sys {
              id
            }
            title
            titleEn: title(locale: "en")
          }
        }
        categoryCollection(locale: $language) {
          items {
            sys {
              id
            }
            slug
            title
          }
        }
      }
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
